import React from "react";
import { ListGroup } from "react-bootstrap";

const SoftwareSkills = () => {
  return (
    <>
      <h6>Software Core Competencies</h6>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <i
            style={{ fontSize: "40px", color: "#68A063" }}
            className="fab fa-node"
          ></i>

          <span className="mx-2">Node </span>

          <i
            style={{ fontSize: "40px", color: "#F0DB4F" }}
            className="fab fa-js-square"
          ></i>

          <span className="mx-2">JavaScript </span>

          <i
            style={{ fontSize: "40px", color: "#61DBFB" }}
            className="fab fa-react"
          ></i>
          <span className="mx-2">React </span>
          <i
            style={{ fontSize: "40px", color: "#61DBFB" }}
            className="fab fa-python"
          ></i>
          <span className="mx-2">Python</span>
        </ListGroup.Item>

        <ListGroup.Item>
          <span className="my-2">
            Other competencies include: Pandas,scikit-learn, MongoDB, Firebase,
            PostgreSQL, Swift, C, Fortran, AWS, Docker, Google Cloud, Redux.
          </span>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default SoftwareSkills;
