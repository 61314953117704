import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProfessionalScreen from "./screens/ProfessionalScreen";
import PersonalScreen from "./screens/PersonalScreen";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <ScrollToTop />
          <Route exact path="/" component={HomeScreen} />
          <Route path="/professional" component={ProfessionalScreen} />
          <Route path="/personal" component={PersonalScreen} />
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
