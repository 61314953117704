import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Header = () => {
  return (
    <header>
      <Navbar bg="light" expand="lg" className="front">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <i className="fas fa-code"></i> Yousef Eshaq
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/professional">
                <Nav.Link>Professional</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/personal">
                <Nav.Link>Personal</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
