export const cards = [
  {
    id: 1,
    title: "Professional",
    img: "/images/business.jpeg",
    description: "Learn about my work",
  },
  {
    id: 2,
    title: "Personal",
    img: "/images/business4.jpg",
    description: "Learn about me",
  },
];

export const hobbies = [
  {
    id: 1,
    title: "Cooking",
    img: "images/food.png",
    description:
      "I am a glorified chef wanna be. When I am not busy with work, I am usually thinking about food",
  },

  {
    id: 2,
    title: "Soccer",
    img: "images/messi.jpg",
    description:
      "I like sports in general, but my favourite sport is football (soccer). I am a huge Barcelona fan. Visca el Barça!..",
  },

  {
    id: 3,
    title: "Traveling",
    img: "images/surf.jpg",
    description:
      "I love traveling and experiencing new cultures. I have been to 24 countries and counting!",
  },
];

export const skills = [
  {
    id: 1,
    title: "Scrum Master Certified",
  },
  {
    id: 2,
    title: "Leadership",
  },
  {
    id: 3,
    title: "Machine Learning Certified Professional - IBM",
  },
  {
    id: 4,
    title: "Agile Methodology",
  },
  {
    id: 5,
    title: "Mentoring Teams",
  },
  {
    id: 6,
    title: "Program Management",
  },
  {
    id: 7,
    title: "Engineering Management",
  },
  {
    id: 8,
    title: "Communication",
  },
];
