import React from 'react';
import Welcome from '../components/Welcome';

const HomeScreen = ({ history }) => {
  return (
    <>
      <Welcome history={history} />
    </>
  );
};

export default HomeScreen;
