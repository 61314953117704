import React from "react";
import BusinessCard from "./BusinessCard";
import { cards } from "../data/data";
import { Row, Col } from "react-bootstrap";
import MusicPlayer from "./MusicPlayer";
import Image from "react-bootstrap/Image";

const Welcome = ({ history }) => {
  return (
    <>
      <div className="showcase">
        <div
          dangerouslySetInnerHTML={{
            __html: `<video
                src="https://personalsiteyousef.s3.ca-central-1.amazonaws.com/video7.mp4+13-03-51-378.mp4"
                muted
                autoplay
                loop
                playsinline
              />`,
          }}
        ></div>
      </div>

      <Row className="my-4">
        {/* <Col
          md={12}
          lg={3}
          className="d-flex align-items-center justify-content-center"
        >
          <MusicPlayer />
        </Col> */}
        <Col
          lg={3}
          className="d-flex align-items-center justify-content-center front"
        >
          <h2>Swipe Right</h2>
          <span className="mx-3 animation2" style={{ fontSize: "35px" }}>
            <i className="fas fa-arrow-right"></i>
          </span>
        </Col>
        {cards.map((card) => (
          <Col lg={3} key={card.id}>
            <BusinessCard
              title={card.title}
              img={card.img}
              description={card.description}
              history={history}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Welcome;
