import React, { useState } from "react";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
  Alert,
} from "react-bootstrap";
import { hobbies } from "../data/data";

const PersonalScreen = () => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <>
      <h1># Personal</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h5>Where it all started</h5>
              <Row className="my-3">
                <Col xs={4} sm={2}>
                  <Image className="animation3" fluid src="images/babyme.jpg" />
                </Col>
                <Col xs={7} sm={10} className="d-flex align-items-center">
                  <span>
                    I am from beautiful Canada! I moved to Montreal in 2011
                    (wow, time flies..<i className="far fa-clock"></i>) to
                    persue an education at McGill University. I fell in love
                    with Montreal and have been here ever since. I am not going
                    to lie, the winters are brutal but there is something
                    magical about a snowy winter morning and a good cup of
                    coffee.
                  </span>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>Things I like to do</h5>
              <Row>
                {hobbies.map((hobbie) => (
                  <Col
                    className="my-3"
                    key={hobbie.id}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <Card className="p-3 rounded h-100">
                      <Card.Body>
                        <Card.Title as="h5">{hobbie.title}</Card.Title>
                        <Card.Text as="div">{hobbie.description}</Card.Text>
                      </Card.Body>
                      <Card.Img variant="top" src={hobbie.img} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4} className="d-block justify-content-center text-center">
          <h5>Fun Facts!</h5>
          {show && (
            <>
              {" "}
              <Alert variant="light" className="my-2">
                I attemped sky diving. I saw my life flash before my eyes ...
              </Alert>
              <Alert variant="secondary" className="my-2">
                I have had shark before. It was surprisingly good!
              </Alert>
              <Alert variant="dark" className="my-2">
                In highschool 12th grade, I scored the highest grade in the
                country.
              </Alert>
            </>
          )}

          <Button
            className={show ? "my-3 " : "my-3 shadow-glow "}
            variant="light"
            onClick={handleClick}
          >
            {show ? "Hide" : "Reveal"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PersonalScreen;
