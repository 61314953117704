import React, { useState } from "react";
import { Card } from "react-bootstrap";
import TinderCard from "react-tinder-card";

const BusinessCard = ({ title, img, description, history }) => {
  const [tag, setTag] = useState(false);
  const onSwipe = (myIdentifier) => {
    setTag(true);
    const page = myIdentifier.toLowerCase();
    setTimeout(function () {
      history.push(`/${page}`);
    }, 100);
  };

  return (
    <TinderCard
      className="d-flex align-items-center justify-content-center "
      onSwipe={() => onSwipe(title)}
      flickOnSwipe={"false"}
      swipeRequirementType={"position"}
      swipeThreshold={100}
    >
      <Card
        className="my-3 p-2 cardcustom animation3 "
        style={{ width: "15rem" }}
      >
        <Card.Img variant="top" src={img} />
        <Card.ImgOverlay></Card.ImgOverlay>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
          {tag && <h5 className="swiped-effect text-center">Swiped!</h5>}
        </Card.Body>
      </Card>
    </TinderCard>
  );
};

export default BusinessCard;
