import React from 'react';
import {skills} from '../data/data';

const SoftSkillsV2 = () => {
  return (
    <>
      <div className='bubbles'>
        <h5>Skills</h5>
        <ul>
        {skills.map((skill)=> (
         <li key={skill.id}>{skill.title}</li>
       ))}
        </ul>
      </div>
    </>
  );
};

export default SoftSkillsV2;
