import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <div>
        <Row>
          <Col className="text-center py-3 front">
            <a className="mx-3" href="https://www.linkedin.com/in/yousefeshaq/">
              <i className="fab fa-linkedin"></i>
            </a>
            <a className="mx-3" href="mailto:yousef.eshaq@mail.mcgill.ca">
              <i className="far fa-envelope"></i>
            </a>
            <a className="mx-3" href="https://github.com/Yeshaq1">
              <i className="fab fa-github"></i>
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">Copyright &copy; Yousef Eshaq</Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
