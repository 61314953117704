import React from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import SideProjects from "../components/SideProjects";
import SoftwareSkills from "../components/SoftwareSkills";
import SoftSkillsV2 from "../components/SoftSkillsV2";

const ProfessionalScreen = () => {
  return (
    <>
      <h1> # Professional</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item></ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col xs={8} className="d-flex align-items-center">
                  <div className="typewriter">
                    <h5>
                      Senior Engineering Manager{" "}
                      <i className="fas fa-laptop-code"></i>
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row className="my-4">
                <Col className="d-block align-items-center">
                  <span>
                    <i
                      style={{ color: "gray" }}
                      className="fas fa-rocket mx-2"
                    ></i>
                    Engineering leader with 8 years of proven track record
                    successfully managing diverse teams across various
                    industries, including finance, insurance, hospitality, smart
                    cities, and machine learning. Currently at Media.Monks,
                    leading squads of Software Engineers-AKA theorists-solving
                    complex challenges for the world's most admired companies.{" "}
                    <i className="fas fa-arrow-right mx-2"></i>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.formula.co/"
                    >
                      Click here
                    </a>
                  </span>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className="my-3">
              <SoftwareSkills />
            </ListGroup.Item>

            <ListGroup.Item className="my-3">
              {/* <SideProjects /> */}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4} className="text-center">
          <SoftSkillsV2 />
          {/* <SoftSkills /> */}
        </Col>
      </Row>
    </>
  );
};

export default ProfessionalScreen;
